@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700|Rubik:400,500,700&display=swap');
* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

html, body {
  min-height: 100%;
  min-width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  background: #000;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Rubik', sans-serif;
}

h1 {
  font-size: 80px;
}

h2 {
  font-size: 60px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 10px;
}

a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all .25s;

  &:hover {
    opacity: .5;
  }
}
