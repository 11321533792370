@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700|Rubik:400,500,700&display=swap);
.header{position:fixed;left:0;top:50px;width:100%;z-index:100}.header .header_container{flex:1 1;display:flex;flex-direction:row;align-items:center;justify-content:space-between;max-width:1350px;margin:0 auto}.header .header_container .header_logo img{pointer-events:none;height:3.5rem}.header .header_container .header_contact{font-size:1.5rem}@media screen and (max-width: 767px){.header_container{padding:0 30px}.header_container .header_logo img{height:2.5rem !important}.header_container .header_contact{font-size:1.2rem !important}}@media screen and (max-width: 1020px){.header_container{padding:0 30px}}@media screen and (max-width: 1390px){.header_container{max-width:980px !important}}

.hero{width:100%}.hero .hero_container{flex:1 1;display:flex;flex-direction:row;align-items:center;justify-content:center;margin:0 auto;max-width:1350px;min-height:100vh}.hero .hero_container .unicorn,.hero .hero_container .description{width:50%;padding:0 30px}.hero .hero_container .unicorn{text-align:right}.hero .hero_container .unicorn img{pointer-events:none;height:30rem}.hero .hero_container .description h1{text-align:left;font-weight:900}@media screen and (orientation: landscape) and (max-width: 920px){.hero_container{flex-direction:column !important}.hero_container .unicorn,.hero_container .description{width:100% !important}.hero_container .unicorn{text-align:center !important}.hero_container .description h1{text-align:center !important}}@media screen and (max-width: 767px){.hero_container{flex-direction:column !important}.hero_container .unicorn,.hero_container .description{width:calc(100% - 60px) !important;text-align:center !important;padding:15px 30px !important}.hero_container .unicorn img{height:auto !important;width:15rem !important}.hero_container .description h1{font-size:4rem !important;text-align:center !important}}@media screen and (max-width: 1390px){.hero_container .unicorn img{width:25rem}.hero_container .description{font-size:6rem}}

.whoweare{position:relative;width:100%}.whoweare .whoweare_container{flex:1 1;display:flex;margin:0 auto;padding:100px 30px 200px 30px;max-width:1350px}.whoweare .whoweare_container img{max-height:60vh !important}.whoweare .whoweare_container .geometric1{pointer-events:none;position:absolute;top:5%;left:5%}.whoweare .whoweare_container .geometric2{pointer-events:none;position:absolute;top:calc(5% + 15px);left:calc(5% + 15px);opacity:.5}.whoweare .whoweare_container .about-us{z-index:10;padding:0 0 0 10%}.whoweare .whoweare_container .about-us p{font-size:1.8rem;max-width:750px;padding:20px 0}.whoweare .whoweare_container .about-us p .intro{font-weight:900}@media screen and (max-width: 767px){.whoweare_container{padding:25px 30px !important}.whoweare_container .geometric1{left:-20% !important}.whoweare_container .geometric2{left:calc(-20% + 15px) !important}.whoweare_container .geometric1 img,.whoweare_container .geometric2 img{width:200vw}.whoweare_container .about-us{padding:30px !important}.whoweare_container .about-us p{font-size:1.4rem !important}}

.whatwedo{position:relative;width:100%}.whatwedo .whatwedo_container{flex:1 1;display:flex;justify-content:center;margin:0 auto;padding:100px 30px;max-width:1350px}.whatwedo .whatwedo_container img{max-height:60vh !important}.whatwedo .whatwedo_container .geometric1{pointer-events:none;position:absolute;top:0;left:45%}.whatwedo .whatwedo_container .geometric2{pointer-events:none;position:absolute;top:15px;left:calc(45% + 15px);opacity:.5}.whatwedo .whatwedo_container .wedo{z-index:10}.whatwedo .whatwedo_container .wedo .title{text-align:center;font-size:3rem;padding:30px 0}.whatwedo .whatwedo_container .wedo p{font-size:1.8rem;max-width:700px;text-align:center;padding:15px 0}.whatwedo .whatwedo_container .wedo p .skills{font-weight:900}@media screen and (max-width: 767px){.whatwedo_container{padding:25px 30px !important}.whatwedo_container .geometric1{left:5% !important;top:35% !important}.whatwedo_container .geometric2{left:calc(5% + 15px) !important;top:calc(35% + 15px) !important}.whatwedo_container .geometric1 img,.whatwedo_container .geometric2 img{width:80vw}.whatwedo_container .wedo{padding:30px !important}.whatwedo_container .wedo .title{font-size:1.8rem !important}.whatwedo_container .wedo p{font-size:1.3rem !important}}

.team{position:relative;width:100%}.team .team_container{flex:1 1;display:flex;margin:0 auto;padding:100px 30px;max-width:1350px}.team .team_container img{max-height:60vh !important}.team .team_container .geometric1{pointer-events:none;position:absolute;top:0;left:-10%}.team .team_container .geometric2{pointer-events:none;position:absolute;top:15px;left:calc(-10% + 15px);opacity:.5}.team .team_container .our-team{z-index:10;padding:70px 0 0 5%;width:100%}.team .team_container .our-team .title{font-size:3rem;padding:0 0 0 70px}.team .team_container .our-team .team_list{flex:1 1;display:flex;flex-direction:row;flex-wrap:wrap;padding:30px 0 0 200px}.team .team_container .our-team .team_list .team_item{flex-direction:column;max-width:300px !important;min-width:30%;font-size:1.2rem;padding:15px 0}.team .team_container .our-team .team_list .team_item h4{font-size:1.8rem}@media screen and (max-width: 767px){.team_container{padding:25px 30px !important}.team_container .geometric1{top:47% !important;left:-20% !important}.team_container .geometric2{top:47% !important;left:calc(-20% + 15px) !important}.team_container .geometric1 img,.team_container .geometric2 img{width:70vw}.team_container .our-team{z-index:10;padding:20px !important;width:100%}.team_container .our-team .title{font-size:1.8rem !important;text-align:center;padding:0 !important}.team_container .our-team .team_list{text-align:center;padding:30px !important}.team_container .our-team .team_list .team_item{min-width:100% !important;font-size:1rem !important}.team_container .our-team .team_list .team_item h4{font-size:1.2rem !important}}

.contact{width:100%;padding-bottom:100px}.contact .contact_container{max-width:750px;margin:0 auto;padding:100px 30px 0 30px;text-align:center}.contact .contact_container .social{margin-top:80px}.contact .contact_container .unicorn img{pointer-events:none;height:7rem;padding-top:50px}.contact .contact_container .social a{margin:0 5px;font-size:2rem}.contact .contact_container .contact_text{font-size:2rem}@media screen and (max-width: 767px){.contact_container{padding:50px 30px 0 30px !important}.contact_container .contact_logo img{height:2.5rem !important}.contact_container .title{font-size:1.8rem !important;padding-bottom:20px}.contact_container .contact_text{font-size:1.2rem !important}}

*{margin:0;padding:0;overflow-x:hidden;-webkit-user-select:none;user-select:none;-webkit-touch-callout:none;-webkit-tap-highlight-color:transparent}html,body{min-height:100%;min-width:100%;font-family:'Roboto', sans-serif;font-size:12px;background:#000;color:#fff}h1,h2,h3,h4,h5,h6{font-family:'Rubik', sans-serif}h1{font-size:80px}h2{font-size:60px}h3{font-size:40px}h4{font-size:30px}h5{font-size:20px}h6{font-size:10px}a{color:#fff;text-decoration:none;cursor:pointer;transition:all .25s}a:hover{opacity:.5}

